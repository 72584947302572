.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.label-input {
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

.grid-item-input {
  width: 100%;
  border: 1px solid #C7C7C7;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #fff;
  height: 48px;
}

.grid-item-select {
  width: 230px;
  background-color: #fff;
  height: 48px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mX-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mX-30 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mX-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mX-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mX-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mX-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mX-1rem {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 8px;
}

.pt-0px-modal {
  padding-top: 0px !important;
}

.pb-1rem {
  padding-bottom: 1rem;
}

.pb-8px {
  padding-bottom: 8px;
}

.pt-15px {
  padding-top: 15px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pX-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.pY-1rem {
  padding-right: 1rem;
  padding-left: 1rem;
}

hr {
  border-bottom: none;
  border-top: 1px solid #C7C7C7;
}

.overflow-hidden {
  overflow: hidden;
}

.relative {
  position: relative;
}

.text-color-white {
  color: white !important;
}

.text-color-black {
  color: black !important;
}

.text-align-center {
  text-align: center;
}

.circulo-inicio-gde {
  width: 800px;
  height: 800px;
  border-radius: 50%;
  border: 2px solid #FFD60E;
  background: transparent;
  position: absolute;
  right: -430px;
  top: 900px;
  overflow: hidden;
}

.circulo-inicio {
  width: 580px;
  height: 580px;
  border-radius: 50%;
  border: 2px solid #FFD60E;
  background: transparent;
  position: absolute;
  right: 120px;
  top: 130px;
  overflow: hidden;
}

.circulo-gde {
  width: 506px;
  height: 506px;
  border-radius: 50%;
  border: 2px solid #FFD60E;
  background: transparent;
  position: absolute;
  right: -410px;
  top: 132px;
  overflow: hidden;
}

.circulo {
  width: 368px;
  height: 368px;
  border-radius: 50%;
  border: 2px solid #FFD60E;
  background: transparent;
  position: absolute;
  right: 69px;
  top: 68px;
  overflow: hidden;
}

@media (max-width: 1024px) {

  .circulo-gde,
  .circulo,
  .circulo-inicio,
  .circulo-inicio-gde {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .vertical-line {
    display: none;
  }
}

@media (min-width: 425px) {
  .MuiGrid-root {
    max-height: 100% !important;
  }
}

@media (min-width: 424px) {
  .MuiContainer-root {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.box-bienvenida {
  height: 100%;
  max-height: 590px;
  display: flex;
  align-items: center;
}

.box-perfilamiento {
  height: 100%;
  max-height: 590px;
}

.box-perfilamiento-form {
  height: 100%;
  max-height: 590px;
  display: flex;
  align-items: center;
}

@media (max-width: 425px) {
  .MuiGrid-root {
    max-height: 100% !important;
  }

  .MuiContainer-root {
    padding-left: 0px;
    padding-right: 0px;
  }

  .normalize-modal {
    padding-bottom: 10px !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .pt-15px-modal {
    padding-top: 15px !important;
  }

  .pb-10px-modal {
    padding-bottom: 10px !important;
  }

  .boton-form {
    height: 46px !important;
    width: 140px !important;
  }

  .box-bienvenida {
    height: 100%;
    max-height: 590px;
  }

  .box-perfilamiento {
    min-height: 100%;
    height: 100%;
    max-height: 590px;
  }

  .box-perfilamiento-form {
    height: 100%;
    max-height: 570px;
  }

  .py-10-m {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media (max-width: 390px) {
  .boton-form-datos {
    height: 48px !important;
    width: 150px !important;
  }
}

@media (min-width: 425px) and (max-width: 1023px) {
  .boton-form-datos {
    height: 46px !important;
    width: 90% !important;
  }
}

@media (max-width: 320px) {
  .MuiButton-root {
    padding: 5px !important;
  }
}

.MuiFormHelperText-root.Mui-error {
  color: #CD0505;
  font-family: Montserrat;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 4px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

select {
  width: 100%;
}

.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  width: 190px;
}

.button-continuar {
  height: 48px;
  font-style: normal;
  font-size: 16px !important;
  text-align: center;
}

.nombre-asegurado {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */


  /* Black */

  color: #212121;
}

.advertencia-exclusion {
  display: flex;
  align-content: center;
  color: #CD0505;
}

.completar-datos {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  /* Azul Inter */

  color: #039ECC;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.space-b {
  justify-content: space-between;
}

.boton-accion-modal {
  width: 100%;
  max-width: 100%;
  height: 48px;
}

.normalize-modal {
  padding-top: 0;
  padding-bottom: 20px;
  padding-right: 10px;
  padding-left: 10px;
  margin: 0px !important;
  width: 100% !important;
}

.asegurado-modal {
  margin: 0px !important;
  width: 100% !important;
}

.minus-pl-32px {
  padding-left: 0px !important;
}

.no-padding {
  padding: 0px !important;
}

.boton-submit {
  height: 48px;
  width: 100%;
}

.boton-form {
  height: 48px;
  width: 100%;
  max-width: 100%;
}

/*
* para escritorioy 425 esta bien,
* para 768 y más pequenio de 425 debe tener regla
*/
.boton-form-datos {
  height: 48px;
  width: 168px;
  max-width: 100%;
}

.div-boton-derecho {
  display: flex;
  justify-content: flex-end;
}

.boton-form-seleccionado {
  background-color: #039ECC !important;
  color: white !important;
}

.boton-para {
  height: 48px;
  max-width: 100%;
  background: #FFFFFF;
  opacity: 0.8;
  /* Black 75% */

  border: 1px solid #595959;
  box-sizing: border-box;
  border-radius: 10px;
}

.border-x {
  border-top: 1px solid rgba(89, 89, 89, .4);
  border-bottom: 1px solid rgba(89, 89, 89, .4);
}

.item-tarjeta {
  color: #212121 !important;
}

.label-enfermedades {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */


  color: #000000;
}

.display-hidden {
  display: hidden !important;
}

textarea {
  width: 100%;
}


.back-button {
  color: #039ECC !important;
}

.hola-txt {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */
  color: #000000;
}

.desc-txt {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.datos-txt {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #000000;
}

.fecha-naciemiento {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */


  color: #000000;
}

.vertical-line {
  position: fixed;
  border: none;
  border-left: 1px solid #595959;
  height: 50%;
  width: 1px;
  margin: 15px 0 10px 0;
  opacity: 0.4;
}

.header-common a,
.header-common .item-menu {
  color: black;
  font-size: 16px;
}

.header-gradient a,
.header-gradient .item-menu {
  color: white;
  font-size: 16px;
}

.case-none {
  text-transform: none !important;
}

.d-flex {
  display: flex;
}

.vertical-divider {
  opacity: 0.4 !important;
  border: 1px solid #595959;
  border-color: #595959 !important;
}

.divider {
  opacity: 0.4;
  /* Black 75% */
  border-top: 1px solid #212121;
  border-color: #212121 !important;
}

.d-flex-center {
  display: flex;
  align-content: center;
  align-items: center;
}

.hidden-movil {
  display: none !important;
}

.p-genero-tooltip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
}

.bg-genero-tooltip {
  background-color: #EBF8FB;
}

.bg-dialog-datos {
  background-color: #F2FAFC;
}

.texto-dialog-datos {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
}

.texto-dialog-aviso-datos {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #595959;
}

.a-dialog-aviso-datos {
  color: #039ECC;
}

.boton-dialog-aviso-datos {
  background-color: #FFFFFF !important;
  border: 1px solid #039ECC;
  color: #039ECC;
}

.texto-pedimos-datos {
  display: flex;
  text-decoration: underline;
  font-size: 14px;
  color: #039ECC;
}

.texto-pedimos-datos-cursor {
  cursor: pointer;
  margin-top: 32px;
}

.text-transform-none {
  text-transform: none;
}

.background-w {
  background-color: white !important;
}

.d-none {
  display: none !important;
}

.accordion-w100 {
  width: 100%;
}

.column-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.text-bold {
  font-weight: 700;
}