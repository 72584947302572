.header-common {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #000000;
  height: 64px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-around;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin: 3px 0 3px 0;
}

.logo-commons {
  background-color: #0b9ecb;
}

.button-login {
  width: 132px;
  height: 40px;
}

.logo-inter {
  width: 137.68px;
  height: 32.02px;
}

/*footer*/
.footer {
  background: #212121;
  width: 100%;
}

/*.footer-fijo {
  position: relative;
  bottom: 0;
  left: 0;
}*/

.header-footer {
  font-weight: 700;
  font-size: 14px;
  color: #fff;
}

.body-footer {
  font-weight: 200;
  font-size: 14px;
  color: #fff;
}

.a {
  text-decoration: none;
}

.logos {
  display: inline-flex;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}
.logos-footer {
  display: inline-flex;
}
.footer-footer {
  font-weight: 200;
  font-size: 10px;
  color: #fff;
}

.container-wrapper {
  background-color: #f4f4f4;
  display: flex;
}

.texto-tarjeta {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #000000;
}

.agregar {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  text-transform: uppercase;

  /* Azul Inter */

  color: #039ecc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tarjeta {
  /* White */
  background: #ffffff;
  /* Shadow-Card */
  box-shadow: 4px 6px 16px rgba(33, 33, 33, 0.05);
  border-radius: 8px;
}

.imagen-flujo {
  max-height: 570px;
  padding: 20px;
}

.imagen-flujo > img {
  max-height: 530px;
  max-width: 440px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

/*itemMenu*/

.promo{
  padding-left: 1.5rem;
}

@media (min-width: 769px) {
  .promo{
    padding-left: 14px;
  }
  .button-login { 
    width: 190px; 
    padding: 6px 14px!important;
    font-size: 15px!important;
  }
  .button-login > svg {
    font-size: 18px;
  }
  .flex-xl {
    justify-content: space-between!important;
  }
}


.MuiMenuItem-root:hover {
  background-color: #039ECC
  !important;
}

.textMovil{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.list-inicio{
  background: #F4F4F4;;
}

.button-text-ayuda{
 
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  color: #212121;
}

@media (max-width: 767px) and (min-width: 360px) {
  .imagen-flujo {
    max-height: 220px;
    padding: 20px;
  }
  
  .imagen-flujo > img {
    max-height: 220px;
    max-width: 195px;
  }

  .logo-inter {
    width: 113.39px;
    height: 26.37px;
  }
}
