@keyframes scale {

	to {
		width: 40px;
		background-color: rgba(66, 182, 217, 1);
	}
}

* {
	font-family: 'Montserrat';
}

h1.section-title {
	font-weight: 700;
	font-size: clamp(20px, 24px, 28px);
	text-align: center;
	line-height: 24px;
	font-style: normal;
	margin-bottom: 1rem;
}

h5.section-subtitle {
	font-weight: 400;
	font-size: clamp(16px, 19px, 22px);
	text-align: center;
	line-height: 27px;
}

div.gallery-slider-container {
	margin-top: 48px;
	overflow-x: scroll;
	cursor: grab;
	scroll-behavior: smooth;
	width: clamp(360px, 85vw,1200px);
}

div.gallery-slider-container::-webkit-scrollbar {
	display: none;
}

.gallery-slider-container > .gallery-slider {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	flex-shrink: 0;
	width: fit-content;
	padding: 10px 10px;
}

.gallery-slider > .card {
	height: 370px;
	width: clamp(240px, 32vw,330px);
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 1);
	margin: 0 24px 0 0;
	padding: 1.5rem 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.gallery-slider > .card > .card-content > h3 {
	text-align: center;
}

.gallery-slider > .card > .card-content > p {
	text-align: center;
	font-size: clamp(12px, 1.5vw, 14px);
}

.gallery-slider > .card > .card-image {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.gallery-slider > .card > .card-image > img {
	width: clamp(160px, 25vw, 214px);
}

.gallery-slider > .card:nth-child(even) {
	background-color: rgba(244, 244, 244, 1);
}

div.indicator-slider-container {
	display: flex;
	flex-direction: row;
	margin-top: 1.5rem;
}

div.indicator-slider-container > .indicator {
	width: 20px;
	height: 10px;
	background-color: rgba(192, 230, 242, 1);
	border-radius: 10px;
	margin-right: 1rem;
}

div.indicator-slider-container > .indicator:hover {
	cursor: pointer;
}

div.indicator-slider-container > .indicator.selected {
	animation: scale 300ms ease forwards;
}

.card > .card-image {
	pointer-events: none;
}